<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div class="title">A must-have E-ticket package for sightseeing in Okinawa. Select popular facilities and restaurants in Okinawa area. You can choose 3  of your favorite facilities within a week. Present the QR code on the E-ticket to easily enter the venue.</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">How to use</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ● The same facility can only be used once, and the same facility can not be re-entered.<br>
          ● Ticket Validity Period: <span>90 Days</span> After Purchase.<br>
          EX: Tickets Purchased On <span>7/1</span> ⇒ <span>Ticket Validity Period:7/1~9/28.</span><br>
          ● Ticket Validity Period: After Scanning The Code At The First Facility, It Is Valid For <span>7 Days.</span><br>
          EX: If You Start To Use It On 7/1 ⇒ Validity Period:<span> 7/1~7/7.</span><br>
          ● Available Facilities: You Can Use <span>3</span>  Facilities During The Valid Period.<br>
          ● Please Note That When You Purchase More Than One Ticket In A Reservation, The Starting Date Will Apply To All Tickets. <br>
          (<span>The Validity Period Is 7 Days</span>).<br>
          ● When The Ticket Has Been Used, The <span>7 Day</span> Validity Period Will Not Exceed The <span>90 Day</span> Validity Period Of The Ticket Itself.<br>
          EX: Ticket Period: <span>7/1~9/28</span>, If The Ticket Starts On <span>9/27</span>, The Valid Time Is: <span>9/27~9/28</span>.<br>
          ● Facilities That Are Unused Cannot Be Refunded.<br>
          ● Please Check The Information About Each Facility, Business Hours, And Public Holidays In Advance At The Following Links.
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='Notice'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='Redemption Location' && !contentItem.noColor">{{cItem}}<br></span>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''}]" target="_blank" :href="cItem" v-show="msg.title==='Official Website' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- notice特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hfokinawa/en/havefun_title_en.jpg'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      dataList: [
        {
          id: 1,
          img: require('@/assets/images/hfokinawa/en/oguri_kimono_salon_EN.jpg'),
          title: ['Oguri Kimono Salon kimono 1,000 yen experience coupon'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '2-chome-3-13 Matsuo, Naha, Okinawa 900-0014 Japan'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['http://oguri-kimono.com/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['You can enjoy a 1000 yen discount coupon. (Only for high school students or above)'] },
                { cid: 2, text: ['Please check the official website in advance for business hours.'] },
                { cid: 3, text: ['If there is a price difference, you will need to pay the balance on-site.'] },
                { cid: 4, text: ['There may be situations where the coupon cannot be used due to the store being full. We apologize for any inconvenience.'] },
                ]
            }
          ]
        },
        {
          id: 2,
          img: require('@/assets/images/hfokinawa/en/chibai_EN.jpg'),
          title: ['Yakiniku Horumon Akashiro - Meal 1000 Yen Coupon'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '〒900-0033 Okinawa, Naha, Kume, 2 Chome−20−21 八木ビル 1階'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.instagram.com/yakinikuhorumon_akashiro/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['You can enjoy a 1000 yen discount coupon. '] },
                { cid: 2, text: ['Please check the official website in advance for business hours.'] },
                { cid: 3, text: ['If there is a price difference, you will need to pay the balance on-site.'] },
                { cid: 4, text: ['There may be situations where the coupon cannot be used due to the store being full. We apologize for any inconvenience.'] },
                ]
            }
          ]
        },
        {
          id: 3,
          img: require('@/assets/images/hfokinawa/en/SZG_EN.jpg'),
          title: ['Okinawa Churaumi Aquarium Admission Ticket'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '1421-5 Yamagawa, Motobu, Kunigami District, Okinawa 905-0206, Japan'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://churaumi.okinawa/en/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please check the official website for the opening hours in advance.','<br>※ In the event of unavoidable circumstances such as a typhoon leading to the suspension of public transportation services, there might be cases where operations are halted.'] },
                ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hfokinawa/en/LQC_EN.jpg'),
          title: ['Ryukyumura Admission Ticket'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '1130 YamadaOnna, Nakagami District, Okinawa'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.ryukyumura.co.jp/official/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please check the official website for the opening hours in advance.','<br>※ In the event of unavoidable circumstances such as a typhoon leading to the suspension of public transportation services, there might be cases where operations are halted.'] },
                ]
            }
          ]
        },
        {
          id: 5,
          img: require('@/assets/images/hfokinawa/en/YQD_EN.jpg'),
          title: ['"Okinawa World Admission Ticket Set (Gyokusendo Cave, Kingdom Village, Habu Museum Park)"'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                'Maekawa-1336 TamagusukuNanjo, Okinawa 901-0616'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.gyokusendo.co.jp/okinawaworld/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please check the official website for the opening hours in advance.','<br>※ In the event of unavoidable circumstances such as a typhoon leading to the suspension of public transportation services, there might be cases where operations are halted.'] },
                ]
            }
          ]
        },
        {
          id: 6,
          img: require('@/assets/images/hfokinawa/en/zwy_EN.jpg'),
          title: ['Southeast Botanical Gardens Admission Ticket【Daytime admission ticket】'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '2146 ChibanaOkinawa,'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.southeast-botanical.jp/en/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please check the official website for the opening hours in advance.','<br>※ In the event of unavoidable circumstances such as a typhoon leading to the suspension of public transportation services, there might be cases where operations are halted.'] },
                { cid: 2, text: ['Entry time: 9:00~17:00','<br>※If you would like to enter the park at night, please purchase a separate ticket on site.'] },
                ]
            }
          ]
        },
        {
          id: 7,
          img: require('@/assets/images/hfokinawa/en/kl_EN.jpg'),
          title: ['DINO Dinosaur PARK Yanbaru Subtropical Forest Admission Ticket'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '1024-1 NakayamaNago, Okinawa'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.okashigoten.co.jp/en/subtropical/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please check the official website for the opening hours in advance.','<br>※ In the event of unavoidable circumstances such as a typhoon leading to the suspension of public transportation services, there might be cases where operations are halted.'] },
                ]
            }
          ]
        },
        {
          id: 8,
          img: require('@/assets/images/hfokinawa/en/bl_EN.jpg'),
          title: ['Okinawa Nago Pinapple Park Admission Ticket(Pineapple Train included)'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '1195 BiimataNago, Okinawa 905-0005'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.nagopine.com/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please check the official website for the opening hours in advance.','<br>※ In the event of unavoidable circumstances such as a typhoon leading to the suspension of public transportation services, there might be cases where operations are halted.'] },
                ]
            }
          ]
        },
        {
          id: 9,
          img: require('@/assets/images/hfokinawa/en/Neopark_EN.jpg'),
          title: ['Neopark Okinawa Admission Ticket + Okinawa light railroad Set Ticket'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '4607-41 Nago, Okinawa 905-0012'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.neopark.co.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please check the official website for the opening hours in advance.','<br>※ Monorail Operating Hours: 10:00 AM to 5:00 PM (Every half-hour, with the last departure at 5:00 PM)'] },
                ]
            }
          ]
        },
        {
          id: 10,
          img: require('@/assets/images/hfokinawa/en/sllr_EN.jpg'),
          title: ['Shuri Ryusen Coral Dyeing Experience: 1000 Yen Coupon (Reservation Required)'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '1-chōme-54 ShuriyamagawachōNaha, Okinawa 903-0825'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.shuri-ryusen.com/']},
                { cid: 2, text: ['Reservation website:'],noColor:true},
                { cid: 3, text: ['ahttps://reserve.shuri-ryusen.com/step1']},
                { cid: 4, text: ['<span style="color:red">※Please choose cash/collect on delivery(COD) as the payment when you make the reservation</span>'] ,noColor:true },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['We operate on a fully reservation-based system. Advance booking is necessary for your use. If there is no prior reservation, the facility might be closed.'] },
                { cid: 2, text: ['Please check the official website for operating hours in advance.'] },
                { cid: 3, text: ['Experience times: 9:30 / 11:00 / 12:30 / 14:00 / 15:30','<br>※ The experience will take approximately 50 minutes, so please arrive 15 minutes before your scheduled time.'] },
                { cid: 4, text: ["There's a possibility of reaching full capacity and not being able to use the service. Please keep this in mind beforehand."] },
                ]
            }
          ]
        },
        {
          id: 11,
          img: require('@/assets/images/hfokinawa/en/haidi_EN.jpg'),
          title: ['Okinawa Naha underwater sightseeing tour - Orca 1000 Yen Coupon'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '2-1 Tsuudoucho, Naha, Okinawa Prefecture, Japan (Naha Port Passenger Waiting Area, WEST MARINE)'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://en.westmarine.co.jp/top.php']},
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please check the official website for operating hours in advance.'] },
                { cid: 2, text: ['The experience will take approximately 50-60 minutes.'] },
                { cid: 3, text: ['In the case of a high number of passengers on the day, entry to the observation deck might be staggered for safety reasons.'] },
                { cid: 4, text: ["The sightseeing boat undergoes regular maintenance. It will not operate during maintenance periods. Additionally, due to unpredictable weather and sea conditions (e.g., strong winds, rough seas, limited visibility), if it's determined that boarding the boat upon arrival is not suitable, you can choose to cancel or reschedule on a case-by-case basis."] },
                { cid: 5, text: ["There's a possibility of reaching full capacity and not being able to use the service. Please keep this in mind beforehand."] },
                ]
            }
          ]
        },
        {
          id: 12,
          img: require('@/assets/images/hfokinawa/en/yuyi_EN.jpg'),
          title: ['Okinawa Naha Mio Kimono/Yukata Experience 1,000 yen discount coupon (Designated entry time: 11:00)'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                'ART Building 2-A, 1-6-1 Kumoji, Naha City, Okinawa 900-0015'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.churasakura.okinawa/#']},
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please check the official website in advance for business hours. '] },
                { cid: 2, text: ['The designated time: 11:00'] },
                { cid: 3, text: ["If it's crowded on-site please wait for a moment. Thank you for your understanding."] },
                { cid: 4, text: ["Normally, rental items are returned by 18:00 on the same day. If you are late, it will be automatically returned to the next day, and you will need to pay an additional 1,100 yen per person when returning the next day. Additionally, you cannot retrieve your belongings on the day of rental, please note. "] },
                { cid: 5, text: ["In the case of irreparable damage to rental items, we will charge a damage fee of 5,000 yen. Please pey the fee with Japanese dollars on-site."] },
                { cid: 6, text: ["It is recommended to wear a bottoming suspender or a tight shirt that is easy to change on the day."] },
                ]
            }
          ]
        },
        {
          id: 13,
          img: require('@/assets/images/hfokinawa/en/heniu_EN.jpg'),
          title: ['Okinawa Ocean View Restaurant Wagyu Grilled Beef Samurai 1,000 Yen Coupon'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '〒904-0115 Okinawa Prefecture Chatan Town Mihama 34-3 Central Tower 6F'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.gyu-samurai.com/']},
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['You can enjoy a 1,000 yen coupon. (Only applicable to the following packages)','<br>Set A menu (slightly change according to the season)<br>Assortment of 2 types of appetizers, assortment of kimchi, premium beef tongue, carefully selected premium beef tenderloin, carefully selected premium beef belly, prefectural Agu pork belly, specially selected sirloin steak, assortment of three types of offal, kelp and egg soup, Rice, dessert, a non-alcoholic drink',
                '<br>Set B menu (slightly change according to the season)<br>Assortment of 3 types of appetizers, salad of the day, assortment of kimchi, premium beef tongue, prime beef tenderloin, prime beef belly, special thick-cut filet, prefectural Agu pork belly, seafood platter of 3, offal three Assortment of species, kelp and egg soup, rice, dessert, a non-alcoholic drink'] },
                { cid: 3, text: ["Please check the official website for business hours in advance."] },
                { cid: 4, text: ["Please understand that the restaurant may be full and the coupon cannot be used."] },
                ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFOkinawa&l=en')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-okinawa-1-week-free-pass/en', '_self')
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>